import { css } from '@emotion/core'

export default () => css`
.explore-brandes-section {
  width: 100%;
  height: 610px;
  padding: 40px 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
}
  
.explore-brandes-section::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
}

.explore-brandes-section .explore-brandes-content-row {
  position: relative;
}

.explore-brandes-section .explore-brandes-content-title {
  color: #fff;
  font-family: "Switzer-Semibold", sans-serif;
  font-size: 42px;
  text-align: center;
}

.explore-brandes-section .explore-brandes-logo-row {
  width: 100%;
  margin-top: 34px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: space-between; 
}

.explore-brandes-logo-holder-link {
  margin: 0 15px;
}

.explore-brandes-section .explore-brandes-logo-holder {
  width: 170px;
  height: 170px;
  padding: 35px;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-content: stretch;
  -moz-content: stretch;
  -ms-content: stretch;
  content: stretch; 
}

@media (max-width: 767px) {
  .explore-brandes-section {
    height: 400px;
    padding: 24px 20px 12px;
  }
  
  .explore-brandes-section .explore-brandes-content-title {
    font-size: 30px; 
  }
  
  .explore-brandes-section .explore-brandes-logo-row {
    margin-top: 12px; 
  }
  
  .explore-brandes-section .explore-brandes-logo-holder {
    width: 100px;
    height: 60px;
    margin-bottom: 12px;
    padding: 15px; 
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .explore-brandes-section {
    height: 500px;
    padding: 40px 30px 28px;
  }
  
  .explore-brandes-section .explore-brandes-content-title {
    font-size: 30px;
  }
  
  .explore-brandes-section .explore-brandes-logo-row {
    margin-top: 12px;
  }
  
  .explore-brandes-section .explore-brandes-logo-holder {
    width: 110px;
    height: 110px;
    margin-bottom: 12px;
    padding: 15px;
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .explore-brandes-section {
    height: 500px;
    padding: 40px 30px 28px;
  }
  
  .explore-brandes-section .explore-brandes-logo-holder {
    width: 140px;
    height: 100px;
    margin-bottom: 12px;
    padding: 20px;
  }
}
`
